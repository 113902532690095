import React, { useRef, useEffect } from 'react'

const CARBON_URL =
  'https://cdn.carbonads.com/carbon.js?serve=CESDK2QM&placement=stevencotterillcom'

export const CarbonAds = React.memo(() => {
  const ref = useRef()

  useEffect(() => {
    const container = ref.current
    const timeout = setTimeout(() => {
      const script = document.createElement('script')
      script.src = CARBON_URL
      script.async = true
      script.id = '_carbonads_js'
      container.appendChild(script)
    }, 100)

    return () => clearTimeout(timeout)
  }, [ref])

  return <div ref={ref}></div>
})
